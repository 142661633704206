@font-face {
  font-family: "FontAwesome";
  src: url("/fonts/fontawesome-webfont.eot?v=4.3.0");
  src: url("/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0")
      format("embedded-opentype"),
    url("/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
    url("/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
    url("/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
    url("/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: 0.3em;
}
.fa.pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #f9f7e2;
}
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-genderless:before,
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
body {
  margin: 0;
  font-family: "open-sans", sans-serif;
  font-size: 14px;
}
.clearfix:after {
  content: ” “; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.no-gutter {
  padding: 0 !important;
}
.website-container {
  padding: 30px;
}
.brand-overlay {
  position: fixed;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 0, 126, 0.6);
  transition: all 0.2s ease-in-out;
}
.show-brand {
  opacity: 1;
  z-index: 999;
  transition: all 0.2s ease-in-out;
}
.hamburger {
  float: right;
}
.hamburger:focus {
  outline: none;
}
.brands-home .hover-insert {
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand-wrapper {
  max-height: 82vh;
  overflow: scroll;
  width: 900px;
  margin: 0 25px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 59px -13px rgba(112, 112, 112, 1);
  -moz-box-shadow: 0px 10px 59px -13px rgba(112, 112, 112, 1);
  box-shadow: 0px 10px 59px -13px rgba(112, 112, 112, 1);
}
.brand-wrapper h1,
.brand-wrapper h3,
.brand-wrapper p {
  text-align: center;
  clear: both;
}
.brand-text {
  margin: 0 100px;
  text-align: center;
}
.brand-image-container {
  max-width: 750px;
  margin: 0 auto;
  margin-top: 35px;
  padding: 0 25px;
  text-align: center;
}
.brand-image-aspect {
  position: relative;
  padding-bottom: 140%;
  margin-left: 5px;
  margin-bottom: 5px;
}
.brand-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.brand-image img {
  width: 100%;
}
.brand-menu {
  border-bottom: 1px solid black;
}
.aspect-wrap {
  position: relative;
  padding-bottom: 50%;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.header-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}
.sub-header-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 50px;
  display: none;
}
.email {
  margin-top: 10px;
  display: inline-block;
}
.header {
  z-index: 50;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-position: top;
}
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 600px;
  z-index: 150;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logo img {
  width: 100%;
}
.sub-logo {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 150;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.text-col {
  text-align: center;
  margin-bottom: 30px;
}
.brand-summary {
  max-width: 540px;
  margin: 0 auto;
}
.hover-signup {
  opacity: 0;
  left: 0;
  top: 0;
  color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 0.5s ease;
  background: none;
}
.hover-insert .fa {
  font-size: 40px;
  color: white;
}
.news-article-img {
  position: relative;
}
.news-article-img img {
  vertical-align: middle;
  width: 100%;
}
.news-article-img .logo-pink {
  width: 100%;
}
.news-article-img:hover .hover-signup {
  display: inline-block;
  transition: all 0.5s ease;
  background: rgba(229, 0, 126, 0.9);
  opacity: 1;
}
.news-article-img:hover .logo-grey {
  display: inline-block;
  transition: all 0.5s ease;
  opacity: 0;
}
.brands-home:hover .hover-signup {
  display: inline-block;
  transition: all 0.5s ease;
  background: rgba(229, 0, 126, 0.9);
  opacity: 1;
}
.newsletter:hover .hover-signup {
  opacity: 1;
  display: inline-block;
  transition: all 0.5s ease;
  background: rgba(229, 0, 126, 0.9);
}
.set-block {
  display: block;
}
.set-block p {
  display: inline-block;
  color: black;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
.hover-insert {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 150;
  width: 100%;
  opacity: 0;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
}
.hover-insert:hover {
  opacity: 1;
  transition: 0.2s ease-in-out;
}
.hover-insert p {
  text-align: center;
}
.hover-insert a {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
}
.hover-insert span {
  margin-top: 15px;
  display: inline-block;
}
.hover-signup p {
  padding: 20px;
}
.hover-signup h2 {
  font-size: 24px;
}
.field {
  display: inline-block;
  width: 60%;
  padding-left: 20px;
  padding-top: 5px;
}
.field input {
  width: 100%;
  background: transparent;
  padding: 10px 0;
  font-size: 14px;
  color: white;
  border: 1px solid white;
}

.newsletter-block {
  background-color: #e5007e;
  padding-top: 70px;
  margin-top: 20px;
  h2 {
    color: white;
    margin-bottom: 10px;
  }
}
.email-field {
  padding-left: 10px;
}
.email-field::-moz-placeholder {
  color: white;
}
.email-field:-ms-input-placeholder {
  color: white;
}
.email-field::placeholder {
  color: #fff; /* Change this to the color you desire */
}
input.subscribe-btn {
  max-width: 200px;
  margin-top: 20px;
}
input.subscribe-btn:hover {
  background-color: white;
  color: #e5007e;
}
#emailSignUp input::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}

#emailSignUpp input:-ms-input-placeholder {
  color: white;
}
.contact-cont {
  background: #e5007e;
  padding: 30px 0;
  text-align: center;
  .field {
    position: relative;
    input {
      border: 1px solid white;
    }
  }
}
.contact-links {
  text-align: center;
}
.contact-links a {
  color: white;
}
.contact-cont h1 {
  color: white;
  text-transform: uppercase;
  text-align: center;
}
.contact-cont h2 {
  display: inline-block;
  color: white;
  margin-top: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
  margin-bottom: 20px;
}
.contact-cont p {
  color: white;
}
.contact-form .field {
  width: 100%;
  padding-left: 0;
}
.contact-form input {
  font-family: "open-sans", sans-serif;
  border: 1px solid #fff !important;
  width: 96%;
  background: transparent;
  border: 0;
  padding: 10px 2%;
  font-size: 14px;
  color: white;
}
.contact-form input:focus {
  outline: none;
}
.contact-form input[type="submit"] {
  margin-top: 10px;
  background: white;
  width: 100%;
  color: #e5007e;
  text-transform: uppercase;
  cursor: pointer;
}
.contact-form textarea {
  font-family: "open-sans", sans-serif;
  border: 1px solid #fff !important;
  width: 96%;
  background: transparent;
  border: 0;
  padding: 10px 2%;
  font-size: 14px;
  color: white;
}
.contact-form textarea:focus {
  outline: none;
}
.contact-form input::-webkit-input-placeholder {
  color: #fff;
}

.contact-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.contact-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.contact-form textarea::-webkit-input-placeholder {
  color: #fff;
}

.contact-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.contact-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.form-cont {
  border: 1px solid black;
  padding: 5px;
  border-radius: 40px;
  width: 80%;
  display: inline-block;
  transition: 0.5s ease-in-out;
}
.heading {
  display: none;
}
#emailSignUp input[type="submit"] {
  text-indent: -9999px;
  display: inline-block;
  background: transparent;
  border: 1px solid black;
  float: right;
  border-radius: 30px;
  background-image: url(../images/submit-arrow.png);
  background-position: center;
  background-repeat: no-repeat;
  padding: 5px;
  width: 46px;
  height: 46px;
}
#emailSignUp .field input {
  outline: none;
  padding-left: 10px !important;
}
#emailSignUp .field input:focus {
  outline: none;
  border: 1px solid black;
}
.inner-text h2 {
  display: inline-block;
  margin-top: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
.inner-text p {
  text-align: justify;
  margin: 0 auto;
  width: 90%;
  padding: 10px 0;
}
.linkIt {
  text-decoration: none;
  padding-bottom: 5px;
  padding-top: 10px;
  border-bottom: 1px solid black;
  color: black;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: inline-block;
}
.linkIt:hover {
  color: #e5007e;
  border-color: #e5007e;
}
.newsletter {
  background: url(../images/newsletter-bg.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.newsletter-cont {
  border: 1px solid black;
  padding-bottom: 0;
}
.more-link-ab {
  position: absolute;
  bottom: -7px;
  width: 100%;
  text-align: center;
  background: white;
  padding: 3px;
}
.news-article {
  text-align: center;
}
.brands-cont {
  padding: 30px 0;
}
.image-separator {
  width: 30px;
  height: 100%;
  top: 0px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  position: absolute;
  z-index: 100;
  background: white;
}
a {
  color: #e5007e;
  text-decoration: none;
}
h1,
h2,
h3 {
  font-weight: 300;
  font-family: "fira-sans", sans-serif;
}
h1 {
  font-size: 28px;
}
.brands-cont h1 {
  text-align: center;
}
h2 {
  font-size: 18px;
}
body {
  color: #333;
}
header {
  text-align: center;
  padding: 20px 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#map {
  height: 550px;
  width: 100%;
  margin-top: 50px;
}
.gm-style-iw * {
  display: block;
  width: 100%;
}
.gm-style-iw h4,
.gm-style-iw p {
  margin: 0;
  padding: 0;
}
.gm-style-iw a {
  color: #4272db;
}
.brands-home {
  background: url(/images/renaissance-home.jpg);
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 550px;
}
.about-img {
  background: url(/images/about-sub-image.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.inner-text {
  padding: 40px 0 80px 0;
}
.newsletter-cont {
  padding: 20px 0;
  padding-bottom: 10px;
}
.inner-text h1,
h2 {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
.signature-wrap {
  text-align: center;
  margin-top: 15px;
}
.signature {
  position: relative;
  width: 160px;
  display: inline-block;
}
.signature span {
  position: absolute;
  top: 0;
  left: 0px;
  font-size: 20px;
}
.signature img {
  width: 100%;
  margin-top: 35px;
  margin-left: 26px;
}
.menu {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}
.menu a {
  padding: 20px 30px;
  display: inline-block;
  text-decoration: none;
  color: black;
  font-family: "fira-sans", sans-serif;
  text-transform: uppercase;
}
.menu a:hover {
  color: #e5007e;
}
.right-menu {
  width: 50%;
  display: inline-block;
  text-align: right;
}
.left-menu {
  width: 49%;
  display: inline-block;
  text-align: left;
}
.fullWidth {
  width: 100%;
}
.new-arrivals {
  display: inline-block;
  width: 100%;
}
.privacy-policy {
  text-align: left;
}
.size4 {
  padding-right: 15px;
}
a.rslides_nav.rslides1_nav.next {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  width: 34px;
  height: 34px;
  background: url(../images/right-arrow.png);
  text-indent: -9999px;
}
a.rslides_nav.rslides1_nav.prev {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 100;
  width: 34px;
  height: 34px;
  background: url(../images/l-arrow.png);
  text-indent: -9999px;
}
a.rslides_nav.rslides2_nav.next {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  width: 34px;
  height: 34px;
  background: url(../images/right-arrow.png);
  text-indent: -9999px;
}
a.rslides_nav.rslides2_nav.prev {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 100;
  width: 34px;
  height: 34px;
  background: url(../images/l-arrow.png);
  text-indent: -9999px;
}
.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
}

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
}
.header-image {
  position: relative;
}
.page-header {
  text-align: center;
  background: white;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  padding: 0 20px;
  z-index: 150;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.page-header h1 {
  font-weight: 400;
  font-size: 36px;
}
footer {
  padding-top: 20px;
  max-width: 1400px;
  margin: 0 auto;
  color: white;
}
.left-foot {
  text-align: left;
}
.right-foot {
  text-align: right;
}
.right-foot p {
  padding-right: 10px;
}
footer .container {
  background: #e5007e;
}
footer p {
  padding-bottom: 5px;
  font-size: 12px;
}
.single-footer {
  padding: 0 70px;
}
.single-footer-first {
  padding-right: 70px;
}
.single-footer-last {
  padding-left: 70px;
}
/*----------Repsonsive nav---*/
.button_container {
  display: none;
  position: fixed;
  top: 10px;
  right: 20px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 500;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.mobile-nav a {
  color: #666;
  font-size: 24px;
  margin-left: 20px;
  vertical-align: top;
  margin-top: 5px;
}
.phone {
  font-size: 26px !important;
  margin-top: 1px;
  display: inline-block;
}
.mobile-nav {
  z-index: 250;
  display: none;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  border-bottom: 1px solid black;
}
.button_container:hover {
  opacity: 0.7;
}
.button_container.active .top {
  -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}
.button_container.active .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active .bottom {
  -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}
.button_container span {
  background: #e5007e;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  z-index: 200;
  position: fixed;
  background: #e5007e;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 0.9;
  visibility: visible;
  height: 100%;
}
.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.overlay nav {
  position: relative;
  height: 70%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  font-family: "fira-sans", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.brand-close-cont {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.notifications p {
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: 25%;
  height: calc(100% / 4);
  min-height: 50px;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}
.overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
  background: #fff;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.footer-social-icons {
  text-align: center;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-social-icons a {
  color: white;
  margin: 0 20px;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.grid {
  list-style: none;
}
.grid > .grid__col--2-of-2,
.grid > .grid__col--3-of-3,
.grid > .grid__col--4-of-4,
.grid > .grid__col--5-of-5,
.grid > .grid__col--6-of-6,
.grid > .grid__col--8-of-8,
.grid > .grid__col--12-of-12 {
  width: 100%;
}
.grid > .grid__col--1-of-2,
.grid > .grid__col--2-of-4,
.grid > .grid__col--3-of-6,
.grid > .grid__col--4-of-8,
.grid > .grid__col--6-of-12 {
  width: 50%;
}
.grid > .grid__col--1-of-3,
.grid > .grid__col--2-of-6,
.grid > .grid__col--4-of-12 {
  width: 33.33333%;
}
.grid > .grid__col--2-of-3,
.grid > .grid__col--4-of-6,
.grid > .grid__col--8-of-12 {
  width: 66.66667%;
}
.grid > .grid__col--1-of-4,
.grid > .grid__col--2-of-8,
.grid > .grid__col--3-of-12 {
  width: 25%;
}
.grid > .grid__col--3-of-4,
.grid > .grid__col--6-of-8,
.grid > .grid__col--9-of-12 {
  width: 75%;
}
.grid > .grid__col--push-2-of-2,
.grid > .grid__col--push-3-of-3,
.grid > .grid__col--push-4-of-4,
.grid > .grid__col--push-5-of-5,
.grid > .grid__col--push-6-of-6,
.grid > .grid__col--push-8-of-8,
.grid > .grid__col--push-12-of-12 {
  margin-left: 100%;
}
.grid > .grid__col--push-1-of-2,
.grid > .grid__col--push-2-of-4,
.grid > .grid__col--push-3-of-6,
.grid > .grid__col--push-4-of-8,
.grid > .grid__col--push-6-of-12 {
  margin-left: 50%;
}
.grid > .grid__col--push-1-of-3,
.grid > .grid__col--push-2-of-6,
.grid > .grid__col--push-4-of-12 {
  margin-left: 33.33333%;
}
.grid > .grid__col--push-2-of-3,
.grid > .grid__col--push-4-of-6,
.grid > .grid__col--push-8-of-12 {
  margin-left: 66.66667%;
}
.grid > .grid__col--push-1-of-4,
.grid > .grid__col--push-2-of-8,
.grid > .grid__col--push-3-of-12 {
  margin-left: 25%;
}
.grid > .grid__col--push-3-of-4,
.grid > .grid__col--push-6-of-8,
.grid > .grid__col--push-9-of-12 {
  margin-left: 75%;
}
.grid > .grid__col--pull-2-of-2,
.grid > .grid__col--pull-3-of-3,
.grid > .grid__col--pull-4-of-4,
.grid > .grid__col--pull-5-of-5,
.grid > .grid__col--pull-6-of-6,
.grid > .grid__col--pull-8-of-8,
.grid > .grid__col--pull-12-of-12 {
  margin-left: -100%;
}
.grid > .grid__col--pull-1-of-2,
.grid > .grid__col--pull-2-of-4,
.grid > .grid__col--pull-3-of-6,
.grid > .grid__col--pull-4-of-8,
.grid > .grid__col--pull-6-of-12 {
  margin-left: -50%;
}
.grid > .grid__col--pull-1-of-3,
.grid > .grid__col--pull-2-of-6,
.grid > .grid__col--pull-4-of-12 {
  margin-left: -33.33333%;
}
.grid > .grid__col--pull-2-of-3,
.grid > .grid__col--pull-4-of-6,
.grid > .grid__col--pull-8-of-12 {
  margin-left: -66.66667%;
}
.grid > .grid__col--pull-1-of-4,
.grid > .grid__col--pull-2-of-8,
.grid > .grid__col--pull-3-of-12 {
  margin-left: -25%;
}
.grid > .grid__col--pull-3-of-4,
.grid > .grid__col--pull-6-of-8,
.grid > .grid__col--pull-9-of-12 {
  margin-left: -75%;
}
.grid > .grid__col--1-of-5 {
  width: 20%;
}
.grid > .grid__col--push-1-of-5 {
  margin-left: 20%;
}
.grid > .grid__col--pull-1-of-5 {
  margin-left: -20%;
}
.grid > .grid__col--2-of-5 {
  width: 40%;
}
.grid > .grid__col--push-2-of-5 {
  margin-left: 40%;
}
.grid > .grid__col--pull-2-of-5 {
  margin-left: -40%;
}
.grid > .grid__col--3-of-5 {
  width: 60%;
}
.grid > .grid__col--push-3-of-5 {
  margin-left: 60%;
}
.grid > .grid__col--pull-3-of-5 {
  margin-left: -60%;
}
.grid > .grid__col--4-of-5 {
  width: 80%;
}
.grid > .grid__col--push-4-of-5 {
  margin-left: 80%;
}
.grid > .grid__col--pull-4-of-5 {
  margin-left: -80%;
}
.grid > .grid__col--1-of-6 {
  width: 16.66667%;
}
.grid > .grid__col--push-1-of-6 {
  margin-left: 16.66667%;
}
.grid > .grid__col--pull-1-of-6 {
  margin-left: -16.66667%;
}
.grid > .grid__col--5-of-6 {
  width: 83.33333%;
}
.grid > .grid__col--push-5-of-6 {
  margin-left: 83.33333%;
}
.grid > .grid__col--pull-5-of-6 {
  margin-left: -83.33333%;
}
.grid > .grid__col--1-of-8 {
  width: 12.5%;
}
.grid > .grid__col--push-1-of-8 {
  margin-left: 12.5%;
}
.grid > .grid__col--pull-1-of-8 {
  margin-left: -12.5%;
}
.grid > .grid__col--3-of-8 {
  width: 37.5%;
}
.grid > .grid__col--push-3-of-8 {
  margin-left: 37.5%;
}
.grid > .grid__col--pull-3-of-8 {
  margin-left: -37.5%;
}
.grid > .grid__col--5-of-8 {
  width: 62.5%;
}
.grid > .grid__col--push-5-of-8 {
  margin-left: 62.5%;
}
.grid > .grid__col--pull-5-of-8 {
  margin-left: -62.5%;
}
.grid > .grid__col--7-of-8 {
  width: 87.5%;
}
.grid > .grid__col--push-7-of-8 {
  margin-left: 87.5%;
}
.grid > .grid__col--pull-7-of-8 {
  margin-left: -87.5%;
}
.grid > .grid__col--1-of-12 {
  width: 8.33333%;
}
.grid > .grid__col--push-1-of-12 {
  margin-left: 8.33333%;
}
.grid > .grid__col--pull-1-of-12 {
  margin-left: -8.33333%;
}
.grid > .grid__col--2-of-12 {
  width: 16.66667%;
}
.grid > .grid__col--push-2-of-12 {
  margin-left: 16.66667%;
}
.grid > .grid__col--pull-2-of-12 {
  margin-left: -16.66667%;
}
.grid > .grid__col--5-of-12 {
  width: 41.66667%;
}
.grid > .grid__col--push-5-of-12 {
  margin-left: 41.66667%;
}
.grid > .grid__col--pull-5-of-12 {
  margin-left: -41.66667%;
}
.grid > .grid__col--7-of-12 {
  width: 58.33333%;
}
.grid > .grid__col--push-7-of-12 {
  margin-left: 58.33333%;
}
.grid > .grid__col--pull-7-of-12 {
  margin-left: -58.33333%;
}
.grid > .grid__col--10-of-12 {
  width: 83.33333%;
}
.grid > .grid__col--push-10-of-12 {
  margin-left: 83.33333%;
}
.grid > .grid__col--pull-10-of-12 {
  margin-left: -83.33333%;
}
.grid > .grid__col--11-of-12 {
  width: 91.66667%;
}
.grid > .grid__col--push-11-of-12 {
  margin-left: 91.66667%;
}
.grid > .grid__col--pull-11-of-12 {
  margin-left: -91.66667%;
}
.grid .grid__col {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding-left: 20px;
  vertical-align: top;
}
@media (max-width: 800px) {
  .brand-text {
    margin: 0 30px;
  }
  .grid .grid__col {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: auto;
    padding-left: 0;
  }
  .newsletter .hover-signup {
    display: block !important;
    opacity: 1;
    background: rgba(229, 0, 126, 0.9);
  }
  .hover-insert {
    transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
  .size4 {
    padding-right: 0;
  }
  footer {
    padding: 0 !important;
  }
  .left-foot {
    text-align: center;
    padding-top: 20px;
  }
  .right-foot {
    text-align: center;
    padding-bottom: 10px;
  }
  .inner-text {
    padding: 20px 0;
  }
  .logo {
    width: 35%;
  }
  .logo img {
    width: 100%;
  }
  .sub-logo {
    width: 60%;
  }
  .sub-logo img {
    width: 100%;
  }
  .grid__col.grid__col--1-of-2.size2 {
    height: 400px !important;
  }
}
@media (max-width: 800px) and (min-width: 480px) {
  .grid .grid__col[class*="grid__col--m-"] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid .grid__col.grid__col--m-1-of-2,
  .grid .grid__col.grid__col--m-2-of-4 {
    width: 50%;
  }
  .grid .grid__col.grid__col--m-1-of-3 {
    width: 33.33333%;
  }
  .grid .grid__col.grid__col--m-2-of-3 {
    width: 66.66667%;
  }
  .grid .grid__col.grid__col--m-1-of-4 {
    width: 25%;
  }
  .grid .grid__col.grid__col--m-3-of-4 {
    width: 75%;
  }
}
@media (max-width: 600px) {
}
@media (max-width: 480px) {
  .website-container {
    padding: 0;
  }
  .brand-summary {
    margin: 0 15px;
  }
  .contact-cont {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sub-header-container {
    display: block;
  }
  .newsletter-cont {
    margin: 0 18px;
  }
  .container .logo {
    display: none;
  }
  .header-container {
    padding-top: 30px;
  }
  .sub-header-container {
    padding-top: 60px;
  }
  h1 {
    font-size: 22px;
  }
  .logo {
    position: static !important;
  }
  .sub-logo {
    position: static;
  }
  .button_container {
    display: block;
  }
  .mobile-nav {
    display: block;
  }
  .menu {
    display: none;
  }
  .image-separator {
    display: none;
  }
  .grid .grid__col[class*="grid__col--s-"] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid .grid__col.grid__col--s-1-of-2,
  .grid .grid__col.grid__col--s-2-of-4 {
    width: 50%;
  }
  .grid .grid__col.grid__col--s-1-of-3 {
    width: 33.33333%;
  }
  .grid .grid__col.grid__col--s-2-of-3 {
    width: 66.66667%;
  }
  .grid .grid__col.grid__col--s-1-of-4 {
    width: 25%;
  }
  .grid .grid__col.grid__col--s-3-of-4 {
    width: 75%;
  }
}
.grid .grid__col--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.grid .grid__col--d-first {
  float: left;
}
.grid .grid__col--d-last {
  float: right;
}
.grid.grid--no-gutter {
  margin-left: 0;
  width: 100%;
}
.grid.grid--no-gutter .grid__col {
  padding-left: 0;
}
.grid.grid--no-gutter .grid__col--span-all {
  margin-left: 0;
  width: 100%;
}
.grid .grid__col--ab {
  vertical-align: bottom;
}
.grid .grid__col--am {
  vertical-align: middle;
}
@charset "UTF-8";
/*-----------------------------------*\

  $TOAST-GRID

  An insane grid.
  You'd be mad to use it.

  Usage
  =====

  Assuming default values:

  <div class="grid">
    <div class="grid__col grid__col--1-of-2">
      A half-width column.
    </div>
    <div class="grid__col grid__col--1-of-4 grid__col--pull-1-of-4">
      A quarter, pulled left by its own width. You get this, right?
    </div>
  </div>


  Customisation
  =============

  $toast-grid-namespace and $toast-grid-column-namespace
  adjusts the class names for the grid. With
  default values, grid wrappers have a class
  of '.grid' and columns '.grid__col'.

  $toast-col-groups(n) adjusts column divisions.
  For example, $toast-col-groups(12) will produce
  a 12-column grid. $col-groups(3,6,8)
  will produce a 3-, 6-, and 8-column grid.

  $toast-gutter-width is—you guessed it—the gutter
  width. Accepts any unit.

  That's it. Have fun.

\*-----------------------------------*/
.grid {
  list-style: none;
  margin-left: -20px;
}

.grid__col--2-of-2,
.grid__col--3-of-3,
.grid__col--4-of-4,
.grid__col--5-of-5,
.grid__col--6-of-6,
.grid__col--8-of-8,
.grid__col--12-of-12 {
  width: 100%;
}

.grid__col--1-of-2,
.grid__col--2-of-4,
.grid__col--3-of-6,
.grid__col--4-of-8,
.grid__col--6-of-12 {
  width: 50%;
}

.grid__col--1-of-3,
.grid__col--2-of-6,
.grid__col--4-of-12 {
  width: 33.33333%;
}

.grid__col--2-of-3,
.grid__col--4-of-6,
.grid__col--8-of-12 {
  width: 66.66667%;
}

.grid__col--1-of-4,
.grid__col--2-of-8,
.grid__col--3-of-12 {
  width: 25%;
}

.grid__col--3-of-4,
.grid__col--6-of-8,
.grid__col--9-of-12 {
  width: 75%;
}

.grid__col--push-2-of-2,
.grid__col--push-3-of-3,
.grid__col--push-4-of-4,
.grid__col--push-5-of-5,
.grid__col--push-6-of-6,
.grid__col--push-8-of-8,
.grid__col--push-12-of-12 {
  margin-left: 100%;
}

.grid__col--push-1-of-2,
.grid__col--push-2-of-4,
.grid__col--push-3-of-6,
.grid__col--push-4-of-8,
.grid__col--push-6-of-12 {
  margin-left: 50%;
}

.grid__col--push-1-of-3,
.grid__col--push-2-of-6,
.grid__col--push-4-of-12 {
  margin-left: 33.33333%;
}

.grid__col--push-2-of-3,
.grid__col--push-4-of-6,
.grid__col--push-8-of-12 {
  margin-left: 66.66667%;
}

.grid__col--push-1-of-4,
.grid__col--push-2-of-8,
.grid__col--push-3-of-12 {
  margin-left: 25%;
}

.grid__col--push-3-of-4,
.grid__col--push-6-of-8,
.grid__col--push-9-of-12 {
  margin-left: 75%;
}

.grid__col--pull-2-of-2,
.grid__col--pull-3-of-3,
.grid__col--pull-4-of-4,
.grid__col--pull-5-of-5,
.grid__col--pull-6-of-6,
.grid__col--pull-8-of-8,
.grid__col--pull-12-of-12 {
  margin-left: -100%;
}

.grid__col--pull-1-of-2,
.grid__col--pull-2-of-4,
.grid__col--pull-3-of-6,
.grid__col--pull-4-of-8,
.grid__col--pull-6-of-12 {
  margin-left: -50%;
}

.grid__col--pull-1-of-3,
.grid__col--pull-2-of-6,
.grid__col--pull-4-of-12 {
  margin-left: -33.33333%;
}

.grid__col--pull-2-of-3,
.grid__col--pull-4-of-6,
.grid__col--pull-8-of-12 {
  margin-left: -66.66667%;
}

.grid__col--pull-1-of-4,
.grid__col--pull-2-of-8,
.grid__col--pull-3-of-12 {
  margin-left: -25%;
}

.grid__col--pull-3-of-4,
.grid__col--pull-6-of-8,
.grid__col--pull-9-of-12 {
  margin-left: -75%;
}

.grid__col--1-of-5 {
  width: 20%;
}

.grid__col--push-1-of-5 {
  margin-left: 20%;
}

.grid__col--pull-1-of-5 {
  margin-left: -20%;
}

.grid__col--2-of-5 {
  width: 40%;
}

.grid__col--push-2-of-5 {
  margin-left: 40%;
}

.grid__col--pull-2-of-5 {
  margin-left: -40%;
}

.grid__col--3-of-5 {
  width: 60%;
}

.grid__col--push-3-of-5 {
  margin-left: 60%;
}

.grid__col--pull-3-of-5 {
  margin-left: -60%;
}

.grid__col--4-of-5 {
  width: 80%;
}

.grid__col--push-4-of-5 {
  margin-left: 80%;
}

.grid__col--pull-4-of-5 {
  margin-left: -80%;
}

.grid__col--1-of-6 {
  width: 16.66667%;
}

.grid__col--push-1-of-6 {
  margin-left: 16.66667%;
}

.grid__col--pull-1-of-6 {
  margin-left: -16.66667%;
}

.grid__col--5-of-6 {
  width: 83.33333%;
}

.grid__col--push-5-of-6 {
  margin-left: 83.33333%;
}

.grid__col--pull-5-of-6 {
  margin-left: -83.33333%;
}

.grid__col--1-of-8 {
  width: 12.5%;
}

.grid__col--push-1-of-8 {
  margin-left: 12.5%;
}

.grid__col--pull-1-of-8 {
  margin-left: -12.5%;
}

.grid__col--3-of-8 {
  width: 37.5%;
}

.grid__col--push-3-of-8 {
  margin-left: 37.5%;
}

.grid__col--pull-3-of-8 {
  margin-left: -37.5%;
}

.grid__col--5-of-8 {
  width: 62.5%;
}

.grid__col--push-5-of-8 {
  margin-left: 62.5%;
}

.grid__col--pull-5-of-8 {
  margin-left: -62.5%;
}

.grid__col--7-of-8 {
  width: 87.5%;
}

.grid__col--push-7-of-8 {
  margin-left: 87.5%;
}

.grid__col--pull-7-of-8 {
  margin-left: -87.5%;
}

.grid__col--1-of-12 {
  width: 8.33333%;
}

.grid__col--push-1-of-12 {
  margin-left: 8.33333%;
}

.grid__col--pull-1-of-12 {
  margin-left: -8.33333%;
}

.grid__col--2-of-12 {
  width: 16.66667%;
}

.grid__col--push-2-of-12 {
  margin-left: 16.66667%;
}

.grid__col--pull-2-of-12 {
  margin-left: -16.66667%;
}

.grid__col--5-of-12 {
  width: 41.66667%;
}

.grid__col--push-5-of-12 {
  margin-left: 41.66667%;
}

.grid__col--pull-5-of-12 {
  margin-left: -41.66667%;
}

.grid__col--7-of-12 {
  width: 58.33333%;
}

.grid__col--push-7-of-12 {
  margin-left: 58.33333%;
}

.grid__col--pull-7-of-12 {
  margin-left: -58.33333%;
}

.grid__col--10-of-12 {
  width: 83.33333%;
}

.grid__col--push-10-of-12 {
  margin-left: 83.33333%;
}

.grid__col--pull-10-of-12 {
  margin-left: -83.33333%;
}

.grid__col--11-of-12 {
  width: 91.66667%;
}

.grid__col--push-11-of-12 {
  margin-left: 91.66667%;
}

.grid__col--pull-11-of-12 {
  margin-left: -91.66667%;
}

.grid__col {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding-left: 20px;
  vertical-align: top;
}
@media (max-width: 700px) {
  .grid__col {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
}
@media (max-width: 700px) and (min-width: 480px) {
  .grid__col[class*="grid__col--m-"] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid__col.grid__col--m-1-of-2,
  .grid__col.grid__col--m-2-of-4 {
    width: 50%;
  }
  .grid__col.grid__col--m-1-of-3 {
    width: 33.33333%;
  }
  .grid__col.grid__col--m-2-of-3 {
    width: 66.66667%;
  }
  .grid__col.grid__col--m-1-of-4 {
    width: 25%;
  }
  .grid__col.grid__col--m-3-of-4 {
    width: 75%;
  }
}
@media (max-width: 480px) {
  .grid__col[class*="grid__col--s-"] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid__col.grid__col--s-1-of-2,
  .grid__col.grid__col--s-2-of-4 {
    width: 50%;
  }
  .grid__col.grid__col--s-1-of-3 {
    width: 33.33333%;
  }
  .grid__col.grid__col--s-2-of-3 {
    width: 66.66667%;
  }
  .grid__col.grid__col--s-1-of-4 {
    width: 25%;
  }
  .grid__col.grid__col--s-3-of-4 {
    width: 75%;
  }
}

.grid__col--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.grid__col--d-first {
  float: left;
}

.grid__col--d-last {
  float: right;
}

.grid--no-gutter {
  margin-left: 0;
  width: 100%;
}
.grid--no-gutter .grid__col {
  padding-left: 0;
}
.grid--no-gutter .grid__col--span-all {
  margin-left: 0;
  width: 100%;
}

.grid__col--ab {
  vertical-align: bottom;
}

.grid__col--am {
  vertical-align: middle;
}
